import React from 'react'

function header() {
  return (
    <div>
      <h1>Allie's Portfolio</h1>
    </div>
  )
}

export default header
